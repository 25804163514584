<script>
import { mapState } from 'vuex';
import { eventUrl } from 'babel-loader!@newsperform/core-component-lib/src/utils/url';
import SideWidget from 'babel-loader!@newsperform/core-component-lib/src/components/SideWidget/SideWidget.vue';
import { getUpcomingEventsFromMeetings } from 'babel-loader!@newsperform/core-component-lib/src/utils/meetingEventHelpers';
import dateTimeUtil from 'babel-loader!@newsperform/core-component-lib/src/utils/dayjs-wrapper';
import {
  RACE_TYPE_HORSE_RACING,
  RACE_TYPE_GREYHOUNDS,
} from 'babel-loader!@newsperform/core-component-lib/src/utils/nameLibrary';
export default {
  name: 'FormGuideSideWidgets',
  components: { SideWidget },
  data() {
    return {
      horseRacingMeetings: [],
      RACE_TYPE_HORSE_RACING,
      raceDayInfo: [
        {
          items: [
            {
              header: 'A-Z Horse List',
              link: '/form-guide/a-z-horses/',
            },
            {
              header: 'Live Weather / Track Conditions',
              link: '/form-guide/track-conditions/',
            },
            {
              header: 'Horse Racing Stats',
              link: '/stats/',
            },
            {
              header: 'Gear Changes',
              link: '/form-guide/gear-changes/',
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState('FormGuide', ['nextTogoMeetings']),
    ...mapState('Global', ['sideWidget']),
    isHorseRacing() {
      return this.$route.path === '/form-guide/horse-racing/';
    },
    isGreyhounds() {
      return this.$route.path === '/form-guide/greyhounds/';
    },
    isFormGuideIndex() {
      const { isHorseRacing, isGreyhounds } = this;
      return isHorseRacing || isGreyhounds;
    },
    nextUpcomingRacesList() {
      const { isFormGuideIndex, nextUpcomingRaces } = this;
      return isFormGuideIndex ? nextUpcomingRaces() : null;
    },
  },
  methods: {
    nextUpcomingRaces() {
      const { isHorseRacing, $brand, nextTogoMeetings } = this;
      const upcomingRaces = isHorseRacing ? nextTogoMeetings.filter(race => race.sportId === '1') : nextTogoMeetings;
      const dateFilters = [];
      const categoryFilters = isHorseRacing ? ['Professional', null] : [];
      const startTime = dateTimeUtil().instance().utc();
      const raceType = isHorseRacing ? RACE_TYPE_HORSE_RACING : RACE_TYPE_GREYHOUNDS;

      const items = getUpcomingEventsFromMeetings(upcomingRaces, categoryFilters, dateFilters, raceType, startTime)
        .map(event => {
          return {
            link: eventUrl(
              {
                id: event.meetingId,
                name: event.meetingName,
                slug: event.meetingSlug,
              },
              event,
              $brand,
              raceType
            ),
            raceNo: event.eventNumber,
            header: event.meetingName,
            targetTime: event.startTime,
          };
        })
        .slice(0, 6);
      return [{ items }];
    },
  },
};
</script>

<template>
  <div class="form-guide-side-widgets">
    <SideWidget
      v-if="isFormGuideIndex && nextUpcomingRacesList"
      heading="Next to Jump"
      :lists="nextUpcomingRacesList"
    />
    <SideWidget v-if="sideWidget" :heading="sideWidget.heading" :lists="[{ items: sideWidget.sideWidgetItems }]" />
    <SideWidget v-if="isFormGuideIndex && raceDayInfo && isHorseRacing" heading="Raceday Info" :lists="raceDayInfo" />
  </div>
</template>

<style lang="scss">
.form-guide-side-widgets {
  max-width: none;
  margin: 20px auto 0;
  width: calc(100% - 10px);
  @include for-tablet-landscape-up {
    max-width: 300px;
    width: 100%;
  }
}
</style>
