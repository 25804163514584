<script>
import { mapState } from 'vuex';
import BottomMenu from 'babel-loader!@newsperform/core-component-lib/src/components/BottomMenu/BottomMenu.vue';
import BookmakerStacker from 'babel-loader!@newsperform/core-component-lib/src/components/BookmakerStacker/BookmakerStacker.vue';
import AdBanner from 'babel-loader!@newsperform/core-component-lib/src/components/AdBanner/AdBanner';
import Header from '../components/Header/Header';
import FormGuideSideWidgets from '../components/FormGuide/FormGuideSideWidgets.vue';
import { loadTealium, updateTealium } from '../plugins/tealium';
import tealium from '~/mixins/tealium';
export default {
  components: {
    Header,
    FormGuideSideWidgets,
    Footer: () => import('../components/Footer/Footer'),
    AdBanner,
    BookmakerStacker,
    MobileNavigation: () => import('../components/Navigation/MobileNavigation'),
    PrivateMessageModal: () => import('../components/PrivateMessages/PrivateMessageModal'),
    BottomMenu,
  },
  mixins: [tealium],
  computed: {
    ...mapState('Global', ['isMobile', 'pageTitle', 'isMobileApp', 'mobileMenuActive', 'isBannerAd']),
    ...mapState('Subscriptions', ['isSubscriber']),
    ...mapState('PrivateMessages', ['messageThreadActive']),
    ...mapState('Session', ['user']),
    ...mapState('Breadcrumbs', {
      breadcrumbs: state => state.list,
    }),
    baseRoute() {
      const path = this.$route.path.split('/');
      return path[1];
    },
    isApp() {
      if (this.isMobileApp) {
        return true;
      }
      const isApp = this.$route?.query?.isApp;
      const isMobApp = isApp === 'true' || isApp === '1';
      this.$store.commit('Global/SET_IS_MOBILE_APP', isMobApp);
      return isMobApp || false;
    },

    displayAppAd() {
      return this.isApp;
    },
  },
  watch: {
    pageTitle(title) {
      // have to wait until data is loaded before
      // updating Tealium tags

      if (title) {
        const { pageTitle, breadcrumbs, user, isSubscriber } = this;
        const sections = breadcrumbs.map(breadCrumb => breadCrumb.name);
        this.$nextTick(() => {
          updateTealium(sections, pageTitle, user, null, isSubscriber);
        });
      }
    },
  },
  beforeMount() {
    const { pageTitle, user, isSubscriber, $route, isMobile, $brand } = this;
    const sections = this.breadcrumbs.map(breadCrumb => breadCrumb.name);
    loadTealium({ sections, pageTitle, user, isSubscriber, $route, isMobile, $brand });
  },
  methods: {
    toggleMobileNav(active) {
      const main = this.$refs.main;
      const footer = document.getElementsByClassName('footer')[0];
      const body = document.getElementsByTagName('body')[0];
      const mobileSearchContainer = document.getElementById('mobile-search-container');
      const top = document.getElementById('top');
      const wrapper = document.getElementById('wrapper');
      if (active) {
        main.style.display = 'none';
        footer.style.display = 'none';
        mobileSearchContainer.style.display = 'inline';
        wrapper.style.padding = '98px 0';
        top.style.height = '98px';
        body.scrollIntoView(true);
      } else {
        main.style.display = 'block';
        footer.style.display = 'block';
        mobileSearchContainer.style.display = 'none';
        wrapper.style.padding = '0px';
        top.style.height = '50px';
      }
      this.$store.commit('Global/TOGGLE_MOBILE_MENU', active);
    },
  },
};
</script>

<template>
  <div
    id="wrapper"
    :class="{
      'wrapper--plus': isSubscriber,
      'wrapper--app': isApp,
      'wrapper--isAd': isBannerAd,
      'wrapper--display-app-ad': displayAppAd,
    }"
  >
    <header>
      <Header :display-app-ad="displayAppAd" @handleToggleMenu="toggleMobileNav" />
    </header>
    <main id="two-column__main" ref="main">
      <div
        :class="`two-column__col two-column__left-col ${isSubscriber ? 'main-content-sub' : 'main-content-non-sub'}`"
      >
        <nuxt />
      </div>
      <div class="two-column__col two-column__right-col">
        <AdBanner v-if="!isMobile" ad-type="ad-halfpage" />
        <BookmakerStacker v-if="!isApp" />
        <FormGuideSideWidgets />
      </div>
    </main>
    <BottomMenu v-if="!isApp" :path-name="baseRoute" @handleToggleMenu="toggleMobileNav" />
    <MobileNavigation v-if="mobileMenuActive" />
    <Footer v-if="!isApp" />
    <PrivateMessageModal v-if="messageThreadActive" />
  </div>
</template>

<style lang="scss">
@import '~@newsperform/core-component-lib/src/components/BottomMenu/_BottomMenu';
@import '~@newsperform/core-component-lib/src/components/BookmakerStacker/styles/_BookmakerStacker';
@import '~@newsperform/core-component-lib/src/components/BookmakerStacker/styles/_BookmakerStackerTile';
@include componentBottomMenu();
@include componentBookmakerStacker($pothos);
@include componentBookmakerStackerTile();

body {
  margin: 0;
}

#two-column__main {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 0px;
  display: flex;
  flex-wrap: wrap;
  @include for-tablet-landscape-up {
    padding: 0 0 50px;
    flex-wrap: nowrap;
  }

  .two-column__left-col {
    width: 100%;
    @include for-tablet-landscape-up {
      width: calc(100% - 350px);
    }
  }

  .two-column__right-col {
    display: block;
    @include for-tablet-landscape-up {
      display: flex;
      flex-direction: column;
      width: 350px;
    }
  }
}

.two-column__col {
  @include for-tablet-landscape-up {
    padding: 10px 0;
  }
}

.bottom-menu-wrap {
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;

  @include for-tablet-landscape-up {
    display: none;
  }
}
.wrapper {
  &--isAd {
    #two-column__main {
      padding-top: 126px;
      @include for-tablet-landscape-up {
        padding: 0 0 50px;
      }
    }
    &.wrapper--plus {
      #header-wrap {
        height: 58px;
      }
      .header-ad-mobile--sticky {
        top: 58px;
      }
    }
  }
  &--plus {
    // Desktop Punters+
    .header-navigation__container {
      .header__logo {
        padding: 14px 5px;
      }
    }
    .main-nav {
      border: 0;
      border-bottom: 8px solid;
      border-image: linear-gradient(to left, $bloodOrange, $peachy);
      border-image-slice: 1;
      color: $white;
      margin: auto;
      text-decoration: none;
    }
    // Mobile Punters+
    #header-wrap {
      border: 0;
      border-bottom: 8px solid;
      border-image: linear-gradient(to left, $bloodOrange, $peachy);
      border-image-slice: 1;
      color: $white;
      margin: auto;
      text-decoration: none;
      .punters-mobile-logo-sizer {
        margin-left: 10px;
        .punters-mobile-brand {
          .punters-mobile-logo {
            width: 120px;
          }
        }
      }
    }
  }
  &--app {
    #two-column__main {
      padding-top: 0;
    }
  }

  &--display-app-ad {
    #two-column__main {
      padding-top: 58px;
    }
  }
}
</style>
